import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getArticleIndividualPriceInfoQueryKey, qkArticleQuickSearch } from './searchQueryKeys';
import { fetchArticleIndividualPriceInfosPrecalculated, fetchArticleSearch } from './articleFetch';
import { getDefaultSearchRequest } from '../model/searchModel';
import { SESSION_STORAGE_KEY_SALESORDERSET_FILTER } from '../../../storageKey';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';
import { mapSearchArticleDtoToBaseArticle } from '../model/baseArticle.mapper';
import { IArticleDto } from '../../../api/hub/hub.models.generated';
import { BaseArticle } from '../model/baseArticle';
import { useLogin } from '../../useLogin';

export function useArticleQuickSearch(debouncedSearchTerm: string, minSearchTermLength: number) {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useLogin();
  const storedSalesOrderSetValue = sessionStorage.getItem(SESSION_STORAGE_KEY_SALESORDERSET_FILTER);
  const salesOrderSetValue = storedSalesOrderSetValue ? JSON.parse(storedSalesOrderSetValue) : false;

  async function prefetchIndividualPriceInfos(mainArticleIds: number[]) {
    const individualPriceInfos = await fetchArticleIndividualPriceInfosPrecalculated(mainArticleIds);
    for (const individualPriceInfo of individualPriceInfos) {
      queryClient.setQueryData(getArticleIndividualPriceInfoQueryKey(individualPriceInfo.mainArticleId), individualPriceInfo);
    }
  }
  const searchQuery = useQuery({
    queryKey: [qkArticleQuickSearch, { ...getDefaultSearchRequest(), searchTerm: debouncedSearchTerm, pageSize: 3 }],
    queryFn: () =>
      executeAsyncActionNew(async () => {
        const result = await fetchArticleSearch({ ...getDefaultSearchRequest(), searchInSalesOrderSet: salesOrderSetValue, searchTerm: debouncedSearchTerm, pageSize: 3 });
        const mainArticleIds = result.articles.map((a) => a.mainArticleId);

        if (isLoggedIn) {
          await prefetchIndividualPriceInfos(mainArticleIds);
        }
        return result;
      }, false),
    enabled: debouncedSearchTerm?.length >= minSearchTermLength
  });

  const hasSearchResults = !!searchQuery.data && searchQuery.data?.itemCount > 0;
  const articles: BaseArticle[] = searchQuery.data?.articles?.map((x: IArticleDto) => mapSearchArticleDtoToBaseArticle(x)) ?? [];

  return { hasSearchResults, articles };
}
