import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import NoImage from '../../../img/noimage.png';
import ImageContainer from '../../../modules/common/components/ImageContainer';
import { buildArticleThumbnailUrlFromArticleNumber } from '../../../util/articleImage';
import { BaseArticle } from '../model/baseArticle';
import { useCatalogArticle } from '../catalog/useCatalogArticle';
import { StoreContext } from '../../../StoreContext';
import { formatPrice } from '../../../util/numberUtil';

interface ISearchSuggestionItemProps {
  article: BaseArticle;
  showPictures?: boolean;
  onArticleClicked: (article: BaseArticle) => void;
  id: string;
}

/*TODO:
- Display as action style
- Add price
 */

export const QuickSearchArticleItem = observer(({ article, showPictures = true, onArticleClicked, id }: ISearchSuggestionItemProps) => {
  const { t } = useTranslation();
  const { userStore } = useContext(StoreContext);
  const catalogArticle = useCatalogArticle(article, true, userStore.actionExclude);

  const handleNavigate = (selectedArticle: BaseArticle) => {
    onArticleClicked(selectedArticle);
  };

  const thumbImageUrl = useMemo(() => {
    const url = buildArticleThumbnailUrlFromArticleNumber(catalogArticle.articleNumber);
    return url;
  }, [catalogArticle]);

  return (
    <ListItemButton tabIndex={0} key={catalogArticle.articleNumber} onClick={() => handleNavigate(catalogArticle)} sx={{ cursor: 'pointer' }} id={id}>
      <ListItemAvatar style={{ marginRight: 8 }}>
        <>{showPictures ? <ImageContainer src={thumbImageUrl} width={80} height={80} /> : <img src={NoImage} alt={t('Kein Bild')} />}</>
      </ListItemAvatar>
      <ListItemText
        secondary={catalogArticle.description}
        primary={`CHF ${formatPrice(catalogArticle.priceInfo.effectivePrice)} ${catalogArticle.unitText ? '/' : ''} ${catalogArticle.unitText}`}
        primaryTypographyProps={{
          sx: {
            color: (theme) => (catalogArticle.displayAsAction ? theme.palette.primary.main : theme.palette.secondary.main)
          }
        }}
        secondaryTypographyProps={{
          sx: {
            color: (theme) => (catalogArticle.displayAsAction ? theme.palette.primary.main : theme.palette.secondary.main)
          }
        }}
      />
    </ListItemButton>
  );
});
