export enum ArticleAllergen {
  glutenfrei = 1,
  krebstierefrei = 2,
  eierfrei = 3,
  fischfrei = 4,
  sojafrei = 5,
  milchfrei = 6,
  selleriefrei = 7,
  senffrei = 8,
  sesamfrei = 9,
  schwefeldioxidSulfitefrei = 10,
  lupinenfrei = 11,
  weichtierefrei = 12,
  nüssefrei = 13,
  erdnussfrei = 16
}

export enum ArticleSpecialAssortment {
  care = 1,
  plantBased = 2,
  smartCuisine = 3
}

export enum Language {
  de = 0,
  fr = 1,
  it = 2
}
