import { IArticleDto } from '../../../api/hub/hub.models.generated';
import { BaseArticle } from './baseArticle';

export function mapSearchArticleDtoToBaseArticle(searchArticle: IArticleDto): BaseArticle {
  return {
    text: searchArticle.text,
    amount: 1,
    articleId: searchArticle.articleId,
    articleNumber: searchArticle.articleNumber,
    externalArticle: false,
    mainArticleId: searchArticle.mainArticleId,
    status: searchArticle.status,
    mainArticleKind: searchArticle.mainArticleKind,
    notInWebShop: searchArticle.notInWebshop,
    isZzArticle: searchArticle.isZzArticle,
    zzArticles: searchArticle.zzArticles
      ? searchArticle.zzArticles
          .filter((article) => {
            // only zzArticles that are available
            return !article.hideCart;
          })
          .map(mapSearchArticleDtoToBaseArticle)
          .sort((a: BaseArticle, b: BaseArticle) => parseInt(a.articleNumber) - parseInt(b.articleNumber))
      : [],
    brand: searchArticle.brand,
    description: searchArticle.description,
    noveltyUntil: searchArticle.noveltyUntil,
    showAction: searchArticle.showAction,
    isAction: searchArticle.isAction,
    comparisonPrice: searchArticle.comparisonPrice,
    hasComparisonPrice: searchArticle.hasComparisonPrice,
    comparisonPriceUnit: searchArticle.comparisonPriceUnit,
    comparisonPriceUnitText: searchArticle.comparisonUnitText,
    labels: searchArticle.labels,
    pictos: searchArticle.pictos ?? [],
    rohstoffHerkunft: searchArticle.rohstoffHerkunft,
    hergestellt: searchArticle.hergestellt,
    abgefuellt: searchArticle.abgefuellt,
    abgepackt: searchArticle.abgepackt,
    sellAmount: searchArticle.sellAmount,
    available: searchArticle.available,
    stopSelling: searchArticle.stopSelling,
    sellUnit: searchArticle.sellUnit,
    priceCode: searchArticle.priceCode,
    approxWeight: searchArticle.approxWeight,
    unitText: searchArticle.unitText,
    orderEndTimes: searchArticle.orderEndTimes,
    ecoScore: searchArticle.ecoScore,
    normalPrice: searchArticle.normalPrice,
    supplierGroupName: searchArticle.supplierGroupName,
    supplierGroupTermsDocumentName: searchArticle.supplierGroupTermsDocumentName,
    supplierGroupMinOrderValue: searchArticle.supplierGroupMinOrderValue,
    isInSalesOrderSet: searchArticle.isInSalesOrderSet,
    isInCoreAssortment: searchArticle.isInCoreAssortment,
    hideCart: searchArticle.hideCart,
    actionValidTo: searchArticle.actionValidTo ? new Date(searchArticle.actionValidTo) : undefined,
    actionPrice: searchArticle.actionPrice
  };
}
