import { useContext } from 'react';
import { StoreContext } from '../StoreContext';
import { useQuery } from '@tanstack/react-query';
import { executeAsyncActionNew } from '../util/exceptionHandler';
import { ARTICLE_STALE_TIME, qkHasPurchasedArticles } from './search/data-access/searchQueryKeys';
import { fetchHasPurchasedArticles } from './search/data-access/useSearchFilter';

export function useLogin() {
  const { userStore } = useContext(StoreContext);

  const isLoggedIn = userStore.isLoggedIn;
  const loginUser = userStore.loginUser;
  const selectedCustomer = userStore.selectedCustomer;
  const actionExclude = userStore.actionExclude;

  // Wenn dem Kunden explizit eine Bestellzeitgruppe zugewiesen ist (ProlixPro -> Kunde -> Tab "Belieferung" -> Kasten "Bestellung"),
  // dann ist der Wert `orderTimetableGroupId` gesetzt. Ansonsten ist der Wert undefined (null in der DB).
  const hasIndividualOrderEndTimes = !!(userStore.loginCustomer?.orderTimetableGroupId ?? undefined);

  const purchasedArticlesQuery = useQuery({
    queryKey: [qkHasPurchasedArticles],
    queryFn: () => executeAsyncActionNew(async () => await fetchHasPurchasedArticles()),
    staleTime: ARTICLE_STALE_TIME,
    enabled: isLoggedIn
  });
  const hasPurchasedArticles = !!purchasedArticlesQuery.data;

  return { isLoggedIn, loginUser, selectedCustomer, hasIndividualOrderEndTimes, actionExclude, hasPurchasedArticles };
}
