import { BaseArticle } from '../model/baseArticle';
import React, { useEffect, useMemo, useRef } from 'react';
import i18n from 'i18next';
import { useScreenSize } from '../../../modules/common/hooks/useScreenSize';
import { ppConstants } from '../../../util/ppConstants';
import { IArticlePriceInfoData } from '../../../api';
import { useArticleIndividualPrice } from '../data-access/useArticleIndividualPrice';
import { formatPrice } from '../../../util/numberUtil';
import { buildArticleThumbnailUrlFromArticleNumber } from '../../../util/articleImage';
import { useTranslation } from 'react-i18next';

export interface CatalogArticle extends BaseArticle {
  selectedZzIndex: number;
  hideCart: boolean;
  handleZZArticleChange: (indexValue: number) => void;
  displayAsAction: boolean;
  secondaryText: string;
  thumbImageUrl: string;
  allZzArticles: BaseArticle[];
  isProfitPlus: boolean;
  formattedSellUnitAmount: string;
  priceInfo: IArticlePriceInfoData;
  quantity: number;
  setQuantity: (quantity: number) => void;
  calculatedTotalPriceText: string;
  formattedComparisonPrice: string;
}

export function useCatalogArticle(baseArticle: BaseArticle, focusAction: boolean = false, actionExclude?: boolean) {
  const isInitialized = useRef(false);
  const { isLargeScreenUp } = useScreenSize();
  const [zzIndex, setZzIndex] = React.useState(0);
  const [quantity, setQuantity] = React.useState<number>(1);
  const { t } = useTranslation();

  const allArticles = (baseArticle.isZzArticle ? [baseArticle, ...baseArticle.zzArticles] : [baseArticle]).sort((a: BaseArticle, b: BaseArticle) => parseInt(a.articleNumber) - parseInt(b.articleNumber));
  const article = allArticles[zzIndex];
  const secondaryText = getSecondaryText(article, isLargeScreenUp);
  const isProfitPlus = useMemo(() => article.status === 's', [article]);

  const thumbImageUrl = useMemo(() => {
    let articleNumber: string;
    if (article.isZzArticle) {
      let smallestSellAmountZZArticle = null;
      for (const zzArt of allArticles) {
        if (!smallestSellAmountZZArticle || smallestSellAmountZZArticle.sellAmount > zzArt.sellAmount) {
          smallestSellAmountZZArticle = zzArt;
        }
      }
      articleNumber = smallestSellAmountZZArticle?.articleNumber ?? article.articleNumber;
    } else {
      articleNumber = article.articleNumber;
    }

    return buildArticleThumbnailUrlFromArticleNumber(articleNumber);
  }, [allArticles, article.articleNumber, article.isZzArticle]);

  const { individualPriceInfo } = useArticleIndividualPrice(article.mainArticleId);

  const displayAsAction = individualPriceInfo ? individualPriceInfo.isAction && article.showAction && !actionExclude : article.isAction && article.showAction && !actionExclude;

  const priceInfo = useMemo(() => {
    return {
      mainArticleId: article.mainArticleId,
      effectivePrice: individualPriceInfo ? individualPriceInfo.effectivePrice : displayAsAction ? article.actionPrice : article.normalPrice,
      regularPrice: individualPriceInfo ? individualPriceInfo.regularPrice : article.normalPrice,
      isAction: displayAsAction,
      graduatedPriceSteps: individualPriceInfo ? individualPriceInfo.graduatedPriceSteps : {},
      isIndividualPrice: individualPriceInfo ? individualPriceInfo.isIndividualPrice : false
    };
  }, [article.mainArticleId, article.isAction, article.actionPrice, article.normalPrice, individualPriceInfo, actionExclude]);

  const handleZZArticleChange = (indexValue: number) => {
    setZzIndex(indexValue);
  };

  const formattedSellUnitAmount = useMemo(() => {
    const sellUnit = article.sellAmount > 1 ? article.sellUnit + ` ${t('sellUnitSeparator')} ` : '';
    const sellAmount = article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight : article.sellAmount;
    const articleUnit = ' ' + article.unitText;
    return `${sellUnit}${sellAmount}${articleUnit}`;
  }, [article, t]);

  useEffect(() => {
    if (!isInitialized && article.isZzArticle && focusAction) {
      const firstActionIndex = allArticles.findIndex((a) => a.isAction && a.showAction);

      if (firstActionIndex !== -1) {
        setZzIndex(firstActionIndex);
      }
    }

    isInitialized.current = true;
  }, [allArticles, article, focusAction]);

  const calculatedTotalPriceText = useMemo(() => {
    if (!priceInfo || !article) {
      return '';
    }

    const currentQuantity = Number.isNaN(quantity) ? 0 : quantity;

    let priceValue = priceInfo.effectivePrice ?? 0;
    const graduatedPriceSteps = priceInfo.graduatedPriceSteps;
    if (graduatedPriceSteps && graduatedPriceSteps.length > 0) {
      for (const stepQuantityString in priceInfo.graduatedPriceSteps) {
        const stepQuantity = parseInt(stepQuantityString);
        const actQuantity = currentQuantity * (article.sellAmount * (article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight! : 1));
        if (actQuantity >= stepQuantity) {
          priceValue = graduatedPriceSteps[stepQuantityString];
        }
      }
    }
    const total = currentQuantity * (article.sellAmount * (article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight! : 1) * priceValue);
    return `CHF ${formatPrice(total)}`;
  }, [article, priceInfo, quantity]);

  const formattedComparisonPrice = useMemo(() => {
    if (individualPriceInfo) {
      const comparisonPrice = article.comparisonPriceUnit ? individualPriceInfo.effectivePrice / article.comparisonPriceUnit : undefined;
      return `CHF ${formatPrice(comparisonPrice ?? 0)} / ${article.comparisonPriceUnitText}`;
    }
    return `CHF ${formatPrice(article.comparisonPrice ?? 0)} / ${article.comparisonPriceUnitText}`;
  }, [article.comparisonPrice, article.comparisonPriceUnit, article.comparisonPriceUnitText, individualPriceInfo]);

  return {
    ...article,
    selectedZzIndex: zzIndex,
    handleZZArticleChange,
    displayAsAction: displayAsAction,
    articleNumber: article.articleNumber ?? '',
    secondaryText,
    thumbImageUrl,
    allZzArticles: allArticles,
    isProfitPlus,
    formattedSellUnitAmount,
    priceInfo,
    setQuantity,
    quantity,
    calculatedTotalPriceText,
    formattedComparisonPrice
  } as CatalogArticle;
}

function getSecondaryText(article: BaseArticle, isLargeScreenUp: boolean) {
  const hasBrand = !!article.brand && article.brand.text.trim().length > 0;

  const array = [];
  if (article.articleNumber) {
    array.push(`${i18n.t('Art. Nr.')} ${article.articleNumber}`);
  }
  if (hasBrand) {
    array.push(`${i18n.t('Marke')}: ${article.brand.text}`);
  }

  if (article.rohstoffHerkunft?.length > 0 && isLargeScreenUp) {
    array.push(`${i18n.t('Rohstoffherkunft')}: ${article.rohstoffHerkunft.map((x) => x.text).join(', ')}`);
  }

  if (article.hergestellt?.length > 0 && isLargeScreenUp) {
    array.push(`${i18n.t('Produktionsland')}: ${article.hergestellt.map((x) => x.text).join(', ')}`);
  }

  if (article.abgefuellt?.length > 0 && isLargeScreenUp) {
    array.push(`${i18n.t('Artikel abgefüllt in')}: ${article.abgefuellt.join(', ')}`);
  }

  if (article.abgepackt?.length > 0 && isLargeScreenUp) {
    array.push(`${i18n.t('Artikel abgepackt in')}: ${article.abgepackt.join(', ')}`);
  }

  return array.join(' | ');
}
