import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import { IArticleDto } from '../../../api/hub/hub.models.generated';
import { QuickSearchSuggestionItem } from './QuickSearchSuggestionItem';
import { QuickSearchArticleItem } from './QuickSearchArticleItem';
import { ISuggestTermData } from '../../../api';
import { BaseArticle } from '../model/baseArticle';

interface SearchResultsProps {
  searchTerm: string;
  suggestions: ISuggestTermData[];
  articles: BaseArticle[];
  onArticleClicked: (article: BaseArticle) => void;
  onSearchSuggestionClicked: (term: string) => void;
}

export const QuickSearchResults = ({ articles, searchTerm, suggestions, onSearchSuggestionClicked, onArticleClicked }: SearchResultsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (searchTerm?.length < 3) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant={'body1'} align={'center'} color={'secondary'}>
          {t('Suchvorschläge ab 3 Zeichen!')}
        </Typography>
      </Box>
    );
  }

  if (suggestions.length < 1 && articles.length < 1) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant={'body1'} align={'center'} color={'secondary'}>
          {t('Keine Artikel für diese Suche!')}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container p={1} wrap={isSmallScreen ? 'wrap' : 'nowrap'}>
      <Grid item sx={{ p: 1, minWidth: 250, width: isSmallScreen ? '100%' : 'auto' }}>
        {suggestions.length > 0 && searchTerm.length >= 3 && (
          <>
            <Typography variant={'subtitle2'} gutterBottom={true}>
              {t('Suchvorschläge')}
            </Typography>
            <List id={'search-suggestions-list'}>
              {suggestions.map((suggestion: ISuggestTermData, index: number) => (
                <QuickSearchSuggestionItem key={suggestion.term + suggestion.frequency + index} searchTerm={searchTerm} suggestion={suggestion.term} onSearchSuggestionClicked={onSearchSuggestionClicked} id={'search-suggestions-' + index} />
              ))}
            </List>
          </>
        )}
      </Grid>
      <Grid item>
        <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} />
      </Grid>
      <Grid item p={1}>
        {articles.length > 0 && searchTerm.length >= 3 && (
          <>
            <Typography variant={'subtitle2'}>{t('Produktempfehlungen')}</Typography>
            <List id={'search-suggestions-article-list'}>
              {articles.map((article: BaseArticle, index: number) => (
                <QuickSearchArticleItem key={article.articleId} article={article} onArticleClicked={onArticleClicked} id={'search-article-suggestions-' + index} />
              ))}
            </List>
          </>
        )}
      </Grid>
    </Grid>
  );
};
