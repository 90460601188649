// 2025-02-13, Jonas:
// Ecoscore Images wurden mit ProlixPro 8.15 (November 2023) eingebaut:
// - die Bilder wurden von Hand "bearbeitet" um eine optimale Schärfe und keinen Text zu enthalten
// - die Bilder aus dem Celum Export waren zu unscharf und enthielten Text
// - die Bilder wurden auf den Apache ReverseProxy kopiert (smo10236, smo10235, smo10241, smo13241)
// - die Bilder wurden von der Applikation auf der public URL des ReverseProxies referenziert
// - Der Gedanke war, dass man die Bilder unabhängig von einem ProlixPro release auswechseln könnte
// - Siehe: https://prodega.atlassian.net/browse/PLP-7421
//
// Mit ProlixPro 8.25 (März 2025) wurde folgende Änderung vorgenommen:
// - neue aktuelle Bilder eingebaut (direkt von Sophie geliefert)
// - siehe https://prodega.atlassian.net/browse/WEB-2487
// - die Bilder sind nicht mehr auf dem Apache Reverse Proxy abgelegt,
//    weil Entwicklung keinen Zugriff mehr auf diesen Server hat
// - die Bilder sind jetzt direkt im Source Code des Webshop abgelegt und werden als public asset deployt

export function mapEcoScoreLabelToPictoIconImageUrl(ecoscoreLabel: string) {
  const ecoscoreSmallIconName = ecoscoreLabel.replace('+', '_plus').replace('-', '_minus');
  return `assets/ecoscore/picto/Tag_color_${ecoscoreSmallIconName}.svg`;
}

export function mapEcoScoreLabelToImageUrl(ecoscoreLabel: string) {
  const ecoscoreScoreImageName = ecoscoreLabel.replace('+', '_plus').replace('-', '_minus');
  return `assets/ecoscore/image/Primary_color_horizontal_${ecoscoreScoreImageName}.svg`;
}
