import { CatalogSearchType } from '../../../../catalog/util/catalogConstants';
import { useContext, useEffect, useState } from 'react';
import { getQueryParameterByName } from '../../../../../util/urlUtils';
import { Article } from '../../../../shared/articles/models/article';
import useDebouncedCallback from '../../../hooks/useDebounceCallback';
import MasterDataService from '../../../services/masterDataService';
import CatalogService from '../../../../catalog/services/catalogService';
import { toBase64 } from '../../../../catalog/util/catalogUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from '../../../../../StoreContext';
import { mapSearchArticleDtoToBaseArticle } from '../../../../../features/search/model/baseArticle.mapper';

const useSearch = (isLoggedIn: boolean, catalogSearchType: CatalogSearchType, maxArticleItems: number = 3, debounceDelay: number) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(getQueryParameterByName('q') ?? '');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [hasSearchResults, setHasSearchResults] = useState<boolean>(false);
  const location = useLocation();
  const { catalogStore } = useContext(StoreContext);

  const performSearch = (suggestion?: string) => {
    const term = suggestion ? suggestion.trim() : searchTerm;
    if (term.length > 1) {
      const encodedSearchTerm = encodeURIComponent(term);

      const url = '/catalog?q=' + encodedSearchTerm + '&f=' + toBase64([]);
      if (location.pathname + location.search === url) {
        catalogStore.loadSearch(term, toBase64([]));
      } else {
        navigate('/catalog?q=' + encodedSearchTerm + '&f=' + toBase64([]), {
          replace: true
        });
      }
    }

    if (suggestion) {
      setSearchTerm(term);
    }

    setIsActive(false);
  };

  const changeSearchTerm = (changedSearchString: string) => {
    setSearchTerm(changedSearchString);
  };

  const [searchSuggestions] = useDebouncedCallback(async (searchTerm) => {
    if (searchTerm && searchTerm.length > 1) {
      const response = await MasterDataService.fetchSearchSuggestions(searchTerm);
      const filteredSuggestions = response.filter((x) => x !== searchTerm);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, debounceDelay);

  const [searchArticles] = useDebouncedCallback(async (searchTerm) => {
    if (searchTerm && searchTerm.length > 1) {
      const searchResult = await CatalogService.fetchCatalogItems(isLoggedIn, catalogSearchType, searchTerm, true, 'hwgId', undefined, 0, maxArticleItems);
      setArticles(searchResult.articles ? searchResult.articles.map((a) => Article.createFromDto(a, 1)) : []);
    } else {
      setArticles([]);
    }
  }, debounceDelay);

  useEffect(
    () => {
      searchSuggestions(searchTerm);
      searchArticles(searchTerm);
    },
    [searchTerm, catalogSearchType] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setHasSearchResults(articles.length > 0 || suggestions.length > 0);
  }, [articles, suggestions]);

  return {
    searchTerm,
    hasSearchResults,
    changeSearchTerm,
    performSearch,
    suggestions,
    articles,
    setIsActive,
    isActive
  };
};

export default useSearch;
