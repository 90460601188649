import React from 'react';
import { observer } from 'mobx-react-lite';
import { Article } from '../models/article';
import { getIconSourceFromArticleDetail, IIcon } from '../util/imageSourceWrapper';
import { mapEcoScoreLabelToPictoIconImageUrl } from '../../../../util/ecoscore';
import { PiktoIcon } from '../../../common/components/PiktoIcon';

const ArticleIcons = observer(({ article, actionExclude }: { article: Article; actionExclude?: boolean }) => {
  const icons: IIcon[] = [];

  // if the article has an ecoscore label, append the small ecoscore icon
  // Das Ecoscore Icon ist am Anfang, weil es nicht lazy geladen wird und schon zu Beginn gerendert wird ...
  if (article.ecoscoreLabel) {
    icons.push({
      celumId: 'ecoscore' + article.ecoscoreLabel, // dummy id that is used below as key for React element
      imgSrc: mapEcoScoreLabelToPictoIconImageUrl(article.ecoscoreLabel),
      text: `ECO-SCORE ${article.ecoscoreLabel}`
    });
  }
  if (article.isDeepLoaded) {
    const iconSourceFromArticleDetail = getIconSourceFromArticleDetail(article, actionExclude);
    icons.push(...iconSourceFromArticleDetail);
  }

  const controls = icons.map((icon, index) => {
    return <PiktoIcon key={icon.celumId + index} icon={icon} index={index} />;
  });

  if (icons.length === 0) {
    return <div style={{ display: 'flex' }} />;
  }
  return <div style={{ display: 'flex', marginBottom: '10px', flexWrap: 'wrap' }}>{controls}</div>;
});

export default ArticleIcons;
