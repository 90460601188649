import { useLogin } from '../../useLogin';
import { useQuery } from '@tanstack/react-query';
import { fetchArticleIndividualPriceInfosWithCalculation } from './articleFetch';
import { ARTICLE_STALE_TIME, getArticleIndividualPriceInfoQueryKey } from './searchQueryKeys';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';

export function useArticleIndividualPrice(mainArticleId: number) {
  const { isLoggedIn } = useLogin();

  const catalogQuery = useQuery({
    enabled: isLoggedIn,
    queryKey: getArticleIndividualPriceInfoQueryKey(mainArticleId),
    queryFn: () =>
      executeAsyncActionNew(async () => {
        const individualPriceInfo = await fetchArticleIndividualPriceInfosWithCalculation(mainArticleId);
        return individualPriceInfo; // The full IArticleDetailDTOData is put into the query cache!
      }),
    staleTime: ARTICLE_STALE_TIME
  });

  const individualPriceInfo = catalogQuery.data;
  const isLoading = catalogQuery.isLoading;
  return { isLoading, individualPriceInfo };
}
