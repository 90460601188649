import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { IIcon } from '../../../features/search/catalog/newImageSourceWrapper';

export function PiktoIcon({ icon, index }: { icon: IIcon; index: number }) {
  // 2024-03-18, Jonas: WEB-1597, PLP-8679
  // Das Backend liefert fälschlicherweise auch Icons, welche eine ungültige CelumId haben.
  // Diese Icons sollen nicht angezeigt werden.
  // Dies ist zwar Symptombekämpfung, aber es wird aus Perspektive UX wird das Frontend dadurch robuster ...
  const [imageLoadingFailure, setImageLoadingFailure] = useState(false);

  const imgSrc = icon.imgSrc;

  function failedToLoadImage() {
    setImageLoadingFailure(true);
  }

  if (imageLoadingFailure) {
    return null;
  }

  return (
    <Tooltip key={icon.celumId + index} title={icon.text} placement="top">
      <Box key={icon.celumId + index} mt={0.5} mr={0.5} style={{ width: 28, height: 28 }}>
        <img src={imgSrc} alt={icon.text} onError={failedToLoadImage} />
      </Box>
    </Tooltip>
  );
}
