import React, { useEffect, useRef, useState } from 'react';
import { QuickSearchInput } from './QuickSearchInput';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Dialog, DialogContent, DialogTitle, Grid, Popper } from '@mui/material';
import Paper from '@mui/material/Paper';
import useDebounce from '../../../modules/common/hooks/useDebounce';
import { QuickSearchResults } from './QuickSearchResults';
import { useModalArticleDetailDialogControl } from '../../../modules/shared/articles/util/useModalArticleDetailDialog';
import { useQuickSearchParams } from './useQuickSearchQueryParams';
import { useArticleSuggestions } from '../data-access/useArticleSuggestions';
import { useArticleQuickSearch } from '../data-access/useArticleQuickSearch';
import { useScreenSize } from '../../../modules/common/hooks/useScreenSize';
import IconButton from '@mui/material/IconButton';
import BackIcon from 'mdi-material-ui/ArrowLeft';
import { BaseArticle } from '../model/baseArticle';

const minSearchTermLength = 3;

export const QuickSearch = () => {
  const searchBoxRef = useRef(undefined);
  const { showArticleDetailsDialog } = useModalArticleDetailDialogControl();
  const { quickSearchTerm, setQuickSearchTermQueryParam } = useQuickSearchParams();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { isLargeScreenDown, isMediumScreenDown } = useScreenSize();

  const handleSearch = (searchTerm: string) => {
    setIsActive(false);
    setQuickSearchTermQueryParam(searchTerm);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setIsActive(false);
    setSearchTerm(suggestion);
    setQuickSearchTermQueryParam(suggestion);
  };

  const handleOnFocus = () => {
    setIsActive(true);
  };

  const handleSearchTermChanged = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleMobileFrontSearchTermChanged = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setIsActive(true);
  };

  const handleArticleClick = (article: BaseArticle) => {
    setIsActive(false);
    showArticleDetailsDialog(article.articleNumber);
  };

  const handleClear = () => {
    setSearchTerm('');
    setQuickSearchTermQueryParam('');
  };

  const { hasSuggestions, articleSuggestions } = useArticleSuggestions(debouncedSearchTerm, minSearchTermLength);
  const { hasSearchResults, articles } = useArticleQuickSearch(debouncedSearchTerm, minSearchTermLength);

  useEffect(() => {
    setSearchTerm(quickSearchTerm);
  }, [quickSearchTerm]);

  const hasResults = searchTerm.length >= 3 && (hasSuggestions || hasSearchResults);

  if (isMediumScreenDown) {
    return (
      <>
        <div style={{ width: '100%' }}>
          <QuickSearchInput key={`searchRequestKey${quickSearchTerm}`} value={searchTerm ?? ''} onClear={handleClear} onSearchTermChanged={handleMobileFrontSearchTermChanged} onTextFieldClick={() => setIsActive(true)} />
        </div>
        <Dialog open={isActive} fullScreen={true}>
          <DialogTitle sx={{ pl: 1, pt: 1, pr: 1.5, pb: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
            <Grid container alignItems={'center'}>
              <Grid item>
                <IconButton onClick={() => setIsActive(false)} size={'large'}>
                  <BackIcon sx={{ mt: 0 }} />
                </IconButton>
              </Grid>
              <Grid item xs>
                <QuickSearchInput value={searchTerm ?? ''} onClear={handleClear} onSearchTermChanged={handleSearchTermChanged} performSearch={handleSearch} autoFocus paperElevation={0} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <QuickSearchResults searchTerm={searchTerm} articles={articles} suggestions={articleSuggestions} onArticleClicked={handleArticleClick} onSearchSuggestionClicked={handleSuggestionClick} />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setIsActive(false)}>
      <div style={{ width: '100%' }}>
        <div style={{ width: isLargeScreenDown ? 500 : 650 }}>
          <QuickSearchInput onClear={handleClear} key={`searchRequestKey${quickSearchTerm}`} ref={searchBoxRef} value={searchTerm ?? ''} onSearchTermChanged={handleSearchTermChanged} performSearch={handleSearch} onFocus={handleOnFocus} />
        </div>
        <Popper
          open={isActive && hasResults}
          anchorEl={searchBoxRef.current}
          placement={'bottom'}
          style={{ zIndex: 99999999999, width: isMediumScreenDown ? '100%' : 650 }}
          disablePortal={true}
          modifiers={[
            {
              name: 'flip',
              enabled: false
            },
            {
              name: 'preventOverflow',
              enabled: true
            }
          ]}
        >
          <Paper square>
            <QuickSearchResults searchTerm={searchTerm} articles={articles} suggestions={articleSuggestions} onArticleClicked={handleArticleClick} onSearchSuggestionClicked={handleSuggestionClick} />
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
